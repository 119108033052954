import React from "react";

const About = () => {
  return (
    <div
      name="about"
      className=" box-content pb-11 w-full h-screen bg-gradient-to-b from-gray-800 to-black text-white "
    >
      <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full">
        <div className="pb-4 pt-6">
          <p className="text-4xl font-bold inline border-b-4 border-gray-500">
            About
          </p>
        </div>

        

        <p className="text-xl text-justify pr-0  w-full">
       

Hello and welcome to my portfolio website! My name is Mohd Zubair Alam Ansari, and I am a passionate and motivated frontend developer. As a fresher in the industry, I am excited to showcase my skills, projects, and enthusiasm for web development.

I have a strong foundation in HTML, CSS, and JavaScript, and I am constantly expanding my knowledge and learning new technologies. My goal is to create visually appealing and user-friendly websites that provide a seamless experience for users.

During my journey as a frontend developer, I have worked on several projects, both personal and collaborative. These projects have allowed me to hone my skills in building responsive and accessible web applications. I have experience working with popular frameworks and libraries such as React.js, and I enjoy leveraging their power to create dynamic and interactive websites.

Apart from technical skills, I believe in effective communication and collaboration. I enjoy working in a team environment and thrive on exchanging ideas and knowledge with fellow developers. I am always eager to learn from experienced professionals and adapt to new challenges.

My passion for frontend development is driven by the desire to create meaningful and impactful user experiences. I believe that attention to detail and a strong sense of aesthetics can enhance the overall user journey. By combining my technical skills with creativity, I strive to deliver exceptional results in every project I undertake.

Thank you for visiting my portfolio website. Please explore my projects and feel free to reach out to me for any inquiries or opportunities. I look forward to connecting with you and contributing to innovative web solutions.

<p>Best regards ,</p>
Mohd zubair Alam Ansari 
        </p>
      </div>
    </div>
  );
};

export default About;
